<template>
  <div class="edu-frame" style="background-color: rgb(239 239 239);">
    <edu-top-header></edu-top-header>
    <div class="edu-group" style="margin: 20px auto;">
      <div class="step-tips">您好，请按照流程完成注册！</div>
      <div class="step-tips-detail">请填写手机号及密码进行注册</div>
      <div class="class-form">
        <van-form @submit="register">
          <van-field required v-model="formData.idCardNo" label="身份证号码" placeholder="请输入身份证号码" clearable :rules="[{ required: true, message: '请输入身份证号码' }]" @input="changeIDCardNo"/>
          <van-field required v-model="formData.fullName" label="姓名" placeholder="请输入姓名" clearable :rules="[{ required: true, message: '请填写姓名' }]"/>
          <van-field required readonly clickable name="datetimePicker" :value="formData.birthday_GreStr" label="出生日期" placeholder="点击选择时间" @click="birthdayPicker = true"/>
          <van-field required v-model="formData.mobileTelNo" label="手机号码" placeholder="请输入手机号码" clearable :rules="[{ required: true, message: '请填写手机号码' }]"/>
          <van-field required readonly clickable v-model="formData.volunteerRoleStr" label="志愿者身份" placeholder="请选择申请志愿者身份" clearable @click="showVolunteerRole = true"/>
          <van-field required readonly clickable v-model="formData.volunteerTeam" label="分队名称" placeholder="请选择分队名称" clearable @click="showVolunteerTeam = true"/>

          <van-field v-if="formData.volunteerRole == 1" required clickable v-model="formData.volunteerTeamChild" label="小队名称" placeholder="请填写小队名称" clearable/>
          <van-field v-if="formData.volunteerRole == 2" required readonly clickable v-model="formData.volunteerTeamChild" label="小队名称" placeholder="请填写小队名称" clearable @click="showTeamChilds = true"/>

          <van-field required v-model="formData.password" type="password" label="密码" placeholder="请输入密码" clearable :rules="[{ required: true, message: '请填写密码' }]"/>
          <van-field required v-model="formData.repassword" type="password" label="确认密码" placeholder="确认密码" clearable :rules="[{ required: true, message: '请填写确认密码' }]"/>
          <van-field v-model="formData.verifyCode" center clearable label="验证码" placeholder="请输入验证码">
            <template #button>
              <van-button :disabled="time > 0" size="small" block type="danger" native-type="button" @click="sendSms()">{{verifybtntxt}}</van-button>
            </template>
          </van-field>
          <div class="button-group">
            <van-button size="small" block type="danger" native-type="button" @click="$router.go(-1);">上一步</van-button>
            <van-button :loading="submitting" size="small" block type="danger" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </div>


    <!-- 选择生日 -->
    <van-popup v-model="birthdayPicker" position="bottom">
      <van-datetime-picker v-model="formData.birthday_Gre" value type="date" title="选择年月日"  :min-date="minDate" :max-date="maxDate" @confirm="selectBirthday()" @cancel="birthdayPicker=false"/>
    </van-popup>
    <!-- 选择生日 -->

    <!--选择申请志愿者身份-->
    <van-popup v-model="showVolunteerRole" position="bottom">
      <van-picker title="申请志愿者身份" show-toolbar :columns="volunteerRoleOpts" @confirm="selectVolunteerRole" @cancel="showVolunteerRole=false;"/>
    </van-popup>
    <!--选择申请志愿者身份-->

    <!--选择分队-->
    <van-popup v-model="showVolunteerTeam" position="bottom">
      <van-picker title="志愿者组织架构" show-toolbar :columns="volunteerTeams" @confirm="selectVolunteerTeam" @cancel="showVolunteerTeam=false;"/>
    </van-popup>
    <!--选择分队-->

    <van-popup v-model="showTeamChilds" position="bottom">
      <van-picker title="选择小队" show-toolbar :columns="volunteerTeamChilds" @confirm="selectVolunteerTeamChild" @cancel="showTeamChilds=false;"/>
    </van-popup>


  </div>
</template>
<script>
import EduTopHeader from "@/components/VolunteerHeader";
import {Form, Field, Button,Popup, DatetimePicker, Picker} from 'vant';
import CustomerApi from "@/api/CustomerApi";
import XEUtils from "xe-utils";
import ActivityApi from "@/api/ActivityApi";

export default {
  components: {
    EduTopHeader,
    VanForm: Form,
    VanField: Field,
    VanButton: Button,
    VanPopup: Popup,
    VanDatetimePicker: DatetimePicker,
    VanPicker: Picker
  },
  data() {
    return {
      time: 0,
      submitting: false,
      timeInterval: null,
      verifybtntxt: '获取验证码',
      formData: { },
      verify: { },
      birthdayPicker: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      showVolunteerRole: false,
      showVolunteerTeam: false,
      showTeamChilds: false,
      volunteerRoleOpts: [{ text: '小队长', value: '1' }, { text: '队员', value: '2' }],
      volunteerTeams: [],
      volunteerTeamChilds: [],
      allVolunteerTeamChilds: [],
      curVolunteerTeamId: null
    }
  },
  name: 'register',
  methods: {
    selectBirthday() {
      // 选择出生日期
      // this.formData.birthday_Gre = e
      this.$set(this.formData, 'birthday_GreStr', this.formData.birthday_Gre == null ? null : XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd'))
      // this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))
      this.birthdayPicker = false
    },
    register() {
      // 加载中就跳过
      if (this.submitting == true) {
        return
      }
      // console.log(this.formData)
      if (this.formData.verifyCode != this.verify.verifyCode) {
        this.$dialog.alert({message: "验证码错误。"})
        return
      }
      if (this.formData.mobileTelNo != this.verify.mobile) {
        this.$dialog.alert({message: "手机号码不是获取验证码的手机号码。"})
        return
      }
      if (this.formData.idCardNo == null || this.formData.idCardNo == '') {
        this.$dialog.alert({message: "请输入身份证号码。"})
        return
      }
      if (this.formData.birthday_Gre == null || this.formData.birthday_Gre == '') {
        this.$dialog.alert({message: "请输入出生日期。"})
        return
      }
      if (this.formData.fullName == null || this.formData.fullName == '') {
        this.$dialog.alert({message: "请输入姓名。"})
        return
      }
      if (this.formData.password == null || this.formData.password == '') {
        this.$dialog.alert({message: "请输入密码。"})
        return
      }
      if (this.formData.password != this.formData.repassword) {
        this.$dialog.alert({message: "两次密码输入不一致。"})
        return
      }
      this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))
      this.submitting = true
      CustomerApi.registerVolunteer(this.formData).then(response => {
        this.submitting = false
        if (response.code == 100) {
          this.$dialog.alert({message: "已成功递交资料，耐心等候审核。"})
          this.$router.push({ name: 'vlogin' })
        }
      }).catch(()=>{ this.submitting = false })
      // this.$router.push({ name: 'main' })
    },
    sendSms() {
      if (this.formData.mobileTelNo == null || this.formData.mobileTelNo == '') {
        this.$dialog.alert({message: "请输入手机号码"})
        return
      }

      var self = this
      self.time = 60
      self.timeInterval = setInterval(()=> {
        self.verifybtntxt = self.time + '秒后可重发'
        self.time--
        // console.log(self.time)
        if (self.time <= 0) {
          self.verifybtntxt = '获取验证码'
          self.time = 0
          clearInterval(self.timeInterval)
        }
      }, 1000)

      this.submitting = true
      CustomerApi.getPhoneCode({ mobile: this.formData.mobileTelNo}).then(response => {
        this.submitting = false
        this.verify = response.res
      }).then(() =>{ this.submitting = false })
    },
    changeIDCardNo() {
      // console.log('changeIDCardNo')
      var birthday = this.getBirthDayFrom(this.formData.idCardNo)
      // console.log(birthday)
      this.$set(this.formData, 'birthday_GreStr', birthday)
      this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(birthday, 'yyyy-MM-dd HH:mm:ss'))
    },
    getBirthDayFrom(idCard) {
      var birthday = "";
      if(idCard != null && idCard != ""){
        if(idCard.length == 15){
          birthday = "19"+idCard.substr(6,6);
        } else if(idCard.length == 18){
          birthday = idCard.substr(6,8);
        }

        birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-");
      }

      return birthday;
    },
    selectVolunteerRole(e) {
      // 志愿者身份
      this.formData.volunteerRoleStr = e.text
      this.formData.volunteerRole = e.value
      this.formData.volunteerTeam = null
      this.formData.volunteerTeamChild = null
      this.formData.volunteerTeamChildId = null
      this.curVolunteerTeamId = null
      this.showVolunteerRole = false
      this.getVolunteerTeamsChild()
    },
    selectVolunteerTeam(e) {
      console.log('e == ', e)
      // 选择小队
      this.formData.volunteerTeam = e[1] + ' ' + e[2]

      this.formData.volunteerTeamChild = null
      this.formData.volunteerTeamChildId = null
      for (var i = 0; i < this.allVolunteerTeamChilds.length; i++) {
        if (this.allVolunteerTeamChilds[i].teamName == e[2]) {
          this.curVolunteerTeamId = this.allVolunteerTeamChilds[i].teamId
          break
        }
      }

      this.showVolunteerTeam = false
      this.getVolunteerTeamsChild()
    },
    selectVolunteerTeamChild(e) {
      this.formData.volunteerTeamChildId = e.teamId
      this.formData.volunteerTeamChild = e.text
      this.showTeamChilds = false
    },
    getVolunteerTeams() {
      ActivityApi.getVolunteerTeams({ isTeam: 0 }).then(response => {
        this.volunteerTeams = JSON.parse(JSON.stringify(response.res).replaceAll('childs', 'children'))
        this.setTeamDatas(this.volunteerTeams)
        this.volunteerTeams = [this.volunteerTeams]
      })
    },
    getVolunteerTeamsChild() {
      ActivityApi.getVolunteerTeamChilds({ isTeam: 1, parentTeamId: this.curVolunteerTeamId }).then(response => {
        this.volunteerTeamChilds = JSON.parse(JSON.stringify(response.res).replaceAll('teamName', 'text'))
        console.log('this.volunteerTeamChilds == ', this.volunteerTeamChilds)
      })
    },
    setTeamDatas(vt) {
      vt.text = vt.parent.teamName
      if (vt.children != null) {
        for (var i = 0; i < vt.children.length; i++) {
          this.setTeamDatas(vt.children[i])
        }
      }
    },
    getAllVolunteerTeams() {
      ActivityApi.getVolunteerTeamChilds({  }).then(response => {
        this.allVolunteerTeamChilds = response.res
      })
    },
  },
  mounted() {
    this.getVolunteerTeams()
    this.getAllVolunteerTeams()
    if (this.timeInterval != null) clearInterval(this.timeInterval)
  },
  destroyed() {
    if (this.timeInterval != null) clearInterval(this.timeInterval)
  }
}
</script>
<style scoped>
.step-tips-detail {
  font-size: 14px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 15px;

}
.form-tips{
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.button-group {
  margin: 16px;
  display: flex;
}
.van-button {
  margin: 0 5px;
}
</style>
